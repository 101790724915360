"use client";

import { Inter as FontSans } from "next/font/google";
import { Space_Grotesk as FontHeading } from "next/font/google";

import { HighlightInit } from "@highlight-run/next/client";
import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import { ThemeProvider } from "next-themes";

import { ErrorBoundary } from "@/components/ErrorBoundary";
import { Toaster } from "@/components/shadcn/sonner";
import { env } from "@/env";
import { cn } from "@/lib/utils";
import "@/styles/globals.css";

const fontHeading = FontHeading({
  subsets: ["latin"],
  display: "swap",
  variable: "--font-heading",
});
const fontSans = FontSans({
  subsets: ["latin"],
  display: "swap",
  variable: "--font-body",
});

export default function RootLayout({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  return (
    <ErrorBoundary>
      <html lang="en" suppressHydrationWarning className="font-sans">
        <head />
        <body
          className={cn(
            "min-h-screen bg-background font-sans antialiased flex flex-col w-full",
            fontHeading.variable,
            fontSans.variable,
          )}
        >
          <ThemeProvider
            attribute="class"
            defaultTheme="system"
            disableTransitionOnChange
          >
            {children}
            <Toaster />
            <ProgressBar
              height="4px"
              color="#605DB5"
              options={{ showSpinner: false }}
              shallowRouting
            />
          </ThemeProvider>
          <HighlightInit
            projectId={env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID}
            serviceName={`app-${env.NEXT_PUBLIC_VERCEL_ENV}`}
            tracingOrigins
            networkRecording={{
              enabled: true,
              recordHeadersAndBody: true,
              urlBlocklist: [],
            }}
            excludedHostnames={["localhost"]}
          />
        </body>
      </html>
    </ErrorBoundary>
  );
}
