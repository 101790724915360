import { type ClassValue, clsx } from "clsx";
import { differenceInDays, format, formatRelative } from "date-fns";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function generateRelativeDateString(date: Date): string {
  // If the date is more than 5 days in the future, show the full date
  // formatRelative doesn't show time for dates older than 6 days
  // so we have to override the default behavior.
  if (Math.abs(differenceInDays(new Date(), date)) > 5) {
    return format(date, "MMM d, yyyy h:mm a");
  }

  return formatRelative(date, new Date());
}
